import React, {useEffect, useState} from "react";
import TooltipComponent from "./tooltip.component";
import LoadingSign from '../assets/images/loading-trans.svg'
import PaginationComponent from "./pagination.component";

const TableComponent = (props) => {
    const {
        columns,
        dataSource,
        selectedRowKeys,
        onChangeSelectedRowKeys,
        selectedRows,
        tableClassName,
        headerClassName,
        emptyComponent,
        hoverItem,
        pagination,
        loading
    } = props
    const [selectedRowsState, setSelectedRowsState] = useState([])

    useEffect(() => {
        setSelectedRowsState(selectedRows ? selectedRows : [])
    }, [selectedRows])

    const onCheckedAllHandler = (e) => {
        if (e.target.checked) {
            let data = getRowKeys(dataSource)
            onChangeSelectedRowKeys(data.rowKeys, dataSource)
        } else {
            onChangeSelectedRowKeys([], [])
        }
    }

    const getRowKeys = (data) => {
        let rowKeys = []
        let rows = []
        data.forEach(item => {
            rowKeys.push(item.id)
            rows.push(item)
        })

        return {rowKeys, rows}
    }

    const onCheckHandler = (e, value) => {
        let rowKeys = []
        let rows = []

        if (e.target.checked) {
            rowKeys = [...selectedRowKeys, value.id]
            rows = [...selectedRowsState, value]
        } else {
            rowKeys = selectedRowKeys.filter((item) => item !== value.id)
            rows = selectedRowsState.filter((item) => item.id !== value.id)
        }

        setSelectedRowsState(rows)
        onChangeSelectedRowKeys(rowKeys, rows)
    }

    const bodyComponent = () => {
        let rows = []
        dataSource.map((item, index) => {
            const mainRows = (
                <tr key={index.toString()} className={"group"}>
                    {
                        columns.map((columnItem, idx) => {
                                if (idx === 0 && !!selectedRowKeys) {
                                    return (
                                        <td key={index.toString().concat(idx.toString())}
                                            className={`w-5 pl-3 ${hoverItem && 'group-hover:bg-doctor group-hover:rounded-l-md'}`}>
                                            <div
                                                className={"col items-center justify-around p-1"}>
                                                <input type={"checkbox"}
                                                       className={"accent-toad my-2"}
                                                       checked={selectedRowKeys.includes(item.id)}
                                                       onChange={(e) => onCheckHandler(e, item, index)}/>
                                            </div>
                                        </td>
                                    )
                                } else {
                                    if (!!columnItem.render) {
                                        if (!!columnItem.dataIndex) {
                                            return <td colSpan={item?.colSpan ?? 1}
                                                       className={`${hoverItem && 'group-hover:bg-doctor'} ${idx === columns.length - 1 && 'rounded-r-md'} ${idx === 0 && 'rounded-l-md'} py-1`}
                                                       key={index.toString().concat(idx.toString())}>{columnItem.render(item[columnItem.dataIndex], item, idx)}</td>
                                        } else {
                                            return <td colSpan={item?.colSpan ?? 1}
                                                       className={`${hoverItem && 'group-hover:bg-doctor'} ${idx === columns.length - 1 && 'rounded-r-md'} ${idx === 0 && 'rounded-l-md'}`}
                                                       key={index.toString().concat(idx.toString())}>{columnItem.render(item, index)}</td>
                                        }
                                    } else {
                                        return <td key={index.toString().concat(idx.toString())}
                                                   colSpan={item?.colSpan ?? 1}
                                                   className={`${columnItem.className} ${hoverItem && 'group-hover:bg-doctor'} ${idx === columns.length - 1 && 'rounded-r-md'} ${idx === 0 && 'rounded-l-md'}`}>{item[columnItem.dataIndex]}</td>
                                    }
                                }
                            }
                        )
                    }
                </tr>
            )
            rows.push(mainRows)
        })

        return rows
    }

    return (
        <div className={'h-[75vh] block overflow-y-auto'}>
            <table className={`w-full ${tableClassName} ${hoverItem && 'order-table'}`}>
                <thead className={headerClassName}>
                <tr>
                    {
                        columns.map((item, index) => {
                            switch (item.type) {
                                case "checkbox":
                                    return !!selectedRowKeys && (
                                        <th data-priority={(index + 1).toString()} key={index.toString()}
                                            className={"w-5 pl-3 py-3"}>
                                            <div
                                                className={"col items-center justify-around"}>
                                                <input type={"checkbox"} className={"accent-toad"}
                                                       checked={selectedRowKeys.length > 0 && (selectedRowKeys.length === dataSource.length)}
                                                       onChange={e => onCheckedAllHandler(e)}/>
                                            </div>
                                        </th>
                                    )
                                default:
                                    return (
                                        <th width={item.width}
                                            className={item.classNameHeader}
                                            data-priority={(index + 1).toString()} key={index.toString()}>
                                            {item.addTooltip ? (
                                                <div className={"row items-center justify-center space-x-2"}>
                                                    <p>{item.name}</p>
                                                    <TooltipComponent text={item.tooltipText} slug={"table"}/>
                                                </div>
                                            ) : (
                                                <div>{item.name}</div>
                                            )}
                                        </th>
                                    )
                            }
                        })
                    }
                </tr>
                </thead>
                {
                    !loading &&
                    <tbody>
                    {bodyComponent()}
                    </tbody>
                }
            </table>
            {
                loading ?
                <div className={"w-full row justify-center mt-5"}>
                    <img src={LoadingSign} className={"w-12"} alt={"loadingIcon"}/>
                </div>
                :
                dataSource.length === 0 && (!!emptyComponent ? emptyComponent() :
                <div className={"row justify-center mt-20 items-center font-regular"}>No data</div>)
            }
            {dataSource.length > 0  && !!pagination ? <PaginationComponent/> : null}
        </div>
    )
}

export default TableComponent
