import Modal from "./modal.component";
import CloseIcon from "../assets/images/close-icon.svg";

export default function ModalApproveComponent({isShow, onApprove, onChangeHandler, closeModalHandler}) {
    return (
        <Modal
            visibility={isShow}
            content={
                <div className={"relative"}>
                    <div className={"col justify-center w-96 h-auto p-5 bg-white rounded-xl"}>
                        <p className={"text-monstera text-center font-bold text-lg text-black"}>Approve
                            Request</p>
                        <p className={"text-monstera text-black text-base py-5"}>Silakan lakukan konfirmasi
                            dibawah ini sebelum menekan tombol approve request.</p>
                        <div className="row h-8 w-full items-center gap-2 pb-5">
                            <input
                                required
                                onChange={(e) => onChangeHandler(e.target.value, 'checkbox')}
                                className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                                type={'checkbox'} name="name"
                            />
                            <label className="text-gray-700 text-sm font-bold">Tambahkan Watermark pada
                                Dokumen</label>
                        </div>
                        <div className={'row justify-center'}>
                            <button className={'border px-5 py-1 rounded-lg text-white bg-indigo-500 text-sm'}
                                    onClick={onApprove}>Approve Request
                            </button>
                        </div>
                        <img src={CloseIcon} alt="closeButton"
                             className={`w-5 absolute right-4 top-2 cursor-pointer`}
                             onClick={closeModalHandler}/>
                    </div>
                </div>
            }
        />
    )
}
