import Layout from "../layout";
import LegalDocumentComponent from "./legal-document.component";

export default function LegalDocument(){
    return (
        <Layout>
            <LegalDocumentComponent/>
        </Layout>
    )
}
