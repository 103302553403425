import {createSlice} from '@reduxjs/toolkit'

export const modalAlertSlice = createSlice({
    name: 'modalAlert',
    initialState: {
        alertObj: {
            alertMessage: '',
            alertTitle: '',
            modalVisibility: false
        },
    },
    reducers: {
        setModalAlertVisibility: (state, action) => {
            state.alertObj = {...state.alertObj, ...action.payload}
        },
    },
})

export const {setModalAlertVisibility} = modalAlertSlice.actions
const modalAlertReducer = modalAlertSlice.reducer

export default modalAlertReducer
