import CloseIcon from "../assets/images/close-icon.svg";
import Modal from "./modal.component";

export default function ModalFilterDepartment(props) {
    const {
        isVisible,
        selectedDepartment,
        checkedHandler,
        onCancelHandler,
        onApplyFilter,
        onCloseHandler,
        departmentList
    } = props

    return (
        <Modal
            visibility={isVisible}
            content={
                <div className={"relative"}>
                    <div className={"col justify-center w-96 h-auto p-5 bg-white rounded-xl"}>
                        <p className={"text-monstera text-lg text-black"}>Filter Departmen</p>
                        <div className={"col space-y-4 max-h-56 my-4 overflow-scroll pr-10"}>
                            {departmentList?.map((department, index) => (
                                <div key={index} className={"row space-x-5 pl-8 w-full"}>
                                    <input type="checkbox"
                                           value={selectedDepartment}
                                           className={`accent-monstera`}
                                           name={department}
                                           onChange={checkedHandler}
                                           checked={selectedDepartment.findIndex(s => s === department) === -1 ? false : true}
                                    />
                                    <label
                                        className={"row space-x-1 justify-center text-forged-steel font-regular"}>
                                        {department}
                                    </label>
                                </div>
                            ))}
                        </div>
                        <div className={'row justify-center gap-6'}>
                            <button className={'border px-5 py-1 rounded-lg text-sm'} onClick={onCancelHandler}>
                                Cancel
                            </button>
                            <button className={'border px-5 py-1 rounded-lg text-white bg-indigo-500 text-sm'}
                                    onClick={onApplyFilter}>Apply
                            </button>
                        </div>
                        <img src={CloseIcon} alt="closeButton"
                             className={`w-5 absolute right-4 top-2 cursor-pointer`}
                             onClick={onCloseHandler}/>

                    </div>
                </div>
            }
        />
    )
}
