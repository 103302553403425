import TableComponent from "../../commons/table-component";
import {useEffect, useState} from "react";
import {
    bulkDeleteDocumentApi,
    exportDocumentLegalApi,
    getLegalDocumentListApi,
    setLegalDocumentApi,
    uploadFile
} from "../../apis/request-form.api";
import {Link} from "react-router-dom";
import SearchIcon from '../../assets/images/search.svg'
import CreateDocumentFormComponent from "./create-document-form.component";
import CloseIcon from "../../assets/images/close-icon.svg";
import Modal from "../../commons/modal.component";
import {setToolsTotalData} from "../../reducers/tools.reducer";
import {useDispatch, useSelector} from "react-redux";

export default function LegalDocumentComponent() {
    const dispatch = useDispatch()
    const columns = [
        {
            type: 'checkbox',
            classNameHeader: 'px-3',
        },
        {
            name: 'Nama Dokumen',
            classNameHeader: 'pl-4',
            dataIndex: 'filename',
            render: (filename) => (
                <p className={"font-regular text-forged-steel pl-5"}>{filename}</p>
            )
        },
        {
            name: 'Created at',
            dataIndex: 'created_at_formatted',
            render: (created_at_formatted) => (
                <p className={"font-regular text-forged-steel"}>{created_at_formatted}</p>
            )
        },
        {
            name: 'Link Dokumen',
            dataIndex: 'fileurl',
            render: (fileurl) => (
                <Link target='_blank' to={fileurl}>
                    <p className={"font-regular text-forged-steel"}>View Document</p>
                </Link>
            )
        }
    ]
    const [keyword, setKeyword] = useState('')
    const [dataSource, setDataSource] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isShow, setShow] = useState(false)
    const [fileName, setFileName] = useState('')
    const [file, setFiles] = useState('')
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [isDeleteAlert, setShowDeleteAlert] = useState(false)
    const {currentPage} = useSelector(state => state.toolsReducer)

    useEffect(() => {
        const body = {
            "limit": 20,
            "page": currentPage,
            "search": ""
        }

        setIsLoading(true)
        getLegalDocumentListApi(body)
            .then(result => {
                setDataSource(result?.data?.rows)
                dispatch(setToolsTotalData(result?.data?.total_result))
                setIsLoading(false)
            })
            .catch(err => {
                console.log("err :", err)
                setIsLoading(false)
            })
    }, [currentPage])

    const onCreateHandler = () => {
        setShow(true)
    }

    const closeModalHandler = () => {
        setShow(false)
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        if (!!file?.type && !!fileName) {
            const form = new FormData();
            form.append('file', file);
            uploadFile(form)
                .then(result => {
                    onCreateNewDocument(result?.data?.url)
                })
                .catch(err => console.log("err :", err))
        } else {
            console.log("False :", file, fileName)
        }
    }

    const onCreateNewDocument = (file_url) => {
        const body = {filename: fileName, fileurl: file_url}
        setLegalDocumentApi(body)
            .then(result => {
                window.location.reload()
            })
            .catch(err => {
                console.log("False :", file, fileName)
            })
    }

    const onChangeHandler = (e, slug) => {
        if (slug === 'fileName') {
            setFileName(e.target.value)
        } else {
            setFiles(e?.target?.files[0])
        }
    }

    const onChangeSelectedRowKeys = (ids) => {
        setSelectedRowKeys(ids)
    }

    const onChangeKeywordHandler = (e) => {
        setKeyword(e.target.value)
    }

    const getDataToDisplay = () => {
        if (!!keyword) {
            return dataSource.filter(list => list.filename.toLowerCase().includes(keyword.toLowerCase()))
        } else {
            return dataSource
        }
    }

    const onDeleteBulkHandler = () => {
        bulkDeleteDocumentApi({ids: selectedRowKeys})
            .then(res => {
                setSelectedRowKeys([])
                window.location.reload()
            })
            .catch(err => {
                alert('gagal mengahapus dokumen !')
            })
    }

    const onExportHandler = () => {
        exportDocumentLegalApi({search: keyword, ids: selectedRowKeys})
            .then(res => {
                setSelectedRowKeys([])
                const link = document.createElement('a')
                link.href = res?.data?.download_url
                link.setAttribute('download', `legal-document-${Date.now()}.xlsx`)
                document.body.appendChild(link)
                link.click()
            })
            .catch(err => alert('error export document !!'))
    }

    return (
        <>
            <div className={"col space-y-4 mx-10"}>
                <div className={'row justify-between'}>
                    <div className={'row justify-between gap-2'}>
                        <div
                            className={`row items-center px-2 rounded-lg h-9 w-56 border border-desolace-dew bg-ceramic`}>
                            <img className={"h-5"} src={SearchIcon} alt={'search'}/>
                            <input
                                placeholder={'cari dokumen...'}
                                className={"bg-ceramic font-normal border-0 outline-0 px-3 w-full placeholder:italic"}
                                onChange={onChangeKeywordHandler}
                            />
                        </div>
                        <button className={'border p-2 rounded-lg bg-blue-800 font-bold text-white'}
                                onClick={onExportHandler}>
                            Export
                        </button>
                    </div>
                    <div className={'row justify-between gap-2'}>
                        <button
                            className={`border p-2 rounded-lg ${selectedRowKeys?.length ? 'bg-blue-800 text-white' : 'bg-gray-200'} font-bold`}
                            onClick={() => setShowDeleteAlert(true)}
                            disabled={!selectedRowKeys?.length}>
                            Delete Document
                        </button>
                        <button className={'border p-2 rounded-lg bg-green-800 font-bold text-white'}
                                onClick={onCreateHandler}>
                            Create Document
                        </button>
                    </div>
                </div>
                <TableComponent
                    columns={columns}
                    selectedRowKeys={selectedRowKeys}
                    onChangeSelectedRowKeys={onChangeSelectedRowKeys}
                    headerClassName={"bg-windmill-wings font-semibold text-left text-monstera h-8"}
                    dataSource={getDataToDisplay()}
                    pagination={true}
                    loading={isLoading}
                    hoverItem={true}
                />
            </div>
            {!!isShow && <CreateDocumentFormComponent isShow={isShow} closeModalHandler={closeModalHandler}
                                                      onSubmitHandler={onSubmitHandler}
                                                      onChangeHandler={onChangeHandler}/>}
            {isDeleteAlert &&
            <Modal
                visibility={isDeleteAlert}
                content={
                    <div className={"relative"}>
                        <div className={"col justify-center w-96 h-auto p-5 bg-white rounded-xl"}>
                            <p className={"text-monstera text-center font-bold text-lg text-black"}>Hapus Dokumen</p>
                            <p className={"text-monstera text-black text-base py-5"}>Apa anda yakin menghapus
                                dokumen ini ?</p>
                            <div className={'row justify-center'}>
                                <button className={'border px-5 py-1 rounded-lg text-white bg-indigo-500 text-sm'}
                                        onClick={onDeleteBulkHandler}>Hapus
                                </button>
                            </div>
                            <img src={CloseIcon} alt="closeButton"
                                 className={`w-5 absolute right-4 top-2 cursor-pointer`}
                                 onClick={() => {
                                     setSelectedRowKeys([])
                                     setShowDeleteAlert(false)
                                 }}/>

                        </div>
                    </div>
                }
            />
            }
        </>
    )
}
