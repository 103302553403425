import axios from '../services/axios.config';

const getCompanies: Promise<any> = () => {
    return new Promise((resolve, reject) => {
        axios.get(`/api/v1/legal/master/companies`)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response.data))
    })
}

const uploadFile: Promise<any> = (body) => {
    return new Promise((resolve, reject) => {
        axios.post(`/api/v1/legal/upload/file/`, body)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response.data))
    })
}

const createDocumentReviewApi: Promise<any> = (body) => {
    return new Promise((resolve, reject) => {
        axios.post(`/api/v1/legal/request`, body)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response.data))
    })
}

const getUserDetail: Promise<any> = (body) => {
    return new Promise((resolve, reject) => {
        axios.post(`/api/v1/legal/user/detail`, body)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response.data))
    })
}

const getLegalDocumentsApi: Promise<any> = (body) => {
    return new Promise((resolve, reject) => {
        axios.post(`/api/v1/legal/document/list`, body)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response.data))
    })
}

const getUserDetailApi: Promise<any> = (body) => {
    return new Promise((resolve, reject) => {
        axios.post(`/api/v1/legal/user/detail`, body)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response.data))
    })
}

const getListRequestDocumentApi: Promise<any> = (body) => {
    return new Promise((resolve, reject) => {
        axios.post(`/api/v1/legal/request/list`, body)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response.data))
    })
}

const getDetailRequestDocumentApi: Promise<any> = (body) => {
    return new Promise((resolve, reject) => {
        axios.post(`/api/v1/legal/request/detail`, body)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response.data))
    })
}

const getLegalDocumentListApi: Promise<any> = (body) => {
    return new Promise((resolve, reject) => {
        axios.post(`/api/v1/legal/document/list`, body)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response.data))
    })
}

const setLegalDocumentApi: Promise<any> = (body) => {
    return new Promise((resolve, reject) => {
        axios.post(`/api/v1/legal/document`, body)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response.data))
    })
}

const approveDocumentApi: Promise<any> = (body) => {
    return new Promise((resolve, reject) => {
        axios.put(`/api/v1/legal/request/approve`, body)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response.data))
    })
}

const rejectDocumentApi: Promise<any> = (body) => {
    return new Promise((resolve, reject) => {
        axios.put(`/api/v1/legal/request/reject`, body)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response.data))
    })
}

const validateUserApi: Promise<any> = (body) => {
    return new Promise((resolve, reject) => {
        axios.post(`/api/v1/legal/auth/validate`, body)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response.data))
    })
}

const bulkDeleteDocumentApi: Promise<any> = (body) => {
    return new Promise((resolve, reject) => {
        axios.delete(`/api/v1/legal/document`, {data: body})
            .then(response => resolve(response.data))
            .catch(err => reject(err.response.data))
    })
}

const exportDocumentLegalApi: Promise<any> = (body) => {
    return new Promise((resolve, reject) => {
        axios.post(`/api/v1/legal/document/export`, body)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response.data))
    })
}

const exportDocumentRequestApi: Promise<any> = (body) => {
    return new Promise((resolve, reject) => {
        axios.post(`/api/v1/legal/request/export`, body)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response.data))
    })
}

const getDepartmentListApi: Promise<any> = () => {
    return new Promise((resolve, reject) => {
        axios.get(`/api/v1/legal/master/departments`)
            .then(response => resolve(response.data))
            .catch(err => reject(err.response.data))
    })
}


export {
    getCompanies,
    uploadFile,
    createDocumentReviewApi,
    getUserDetail,
    getLegalDocumentsApi,
    getUserDetailApi,
    getListRequestDocumentApi,
    getDetailRequestDocumentApi,
    getLegalDocumentListApi,
    setLegalDocumentApi,
    approveDocumentApi,
    rejectDocumentApi,
    validateUserApi,
    bulkDeleteDocumentApi,
    exportDocumentLegalApi,
    exportDocumentRequestApi,
    getDepartmentListApi
}
