import {combineReducers, configureStore} from "@reduxjs/toolkit";
import requestDocumentReducer from "./request-document.reducer";
import modalAlertReducer from "./modal-alert.reducer";
import toolsReducer from "./tools.reducer";

const rootReducer = combineReducers({
    requestDocumentReducer,
    modalAlertReducer,
    toolsReducer
})

const store = configureStore({reducer: rootReducer})

export default store
