import './App.css';
import {BrowserRouter} from "react-router-dom";
import RoutePage from "./routes";
import {Provider} from "react-redux";
import store from "./reducers";
import {GoogleOAuthProvider} from '@react-oauth/google';

function App() {
    return (
        <GoogleOAuthProvider clientId="500253168598-kb9opist3m5f4dfrc2mc5j2k6ori1ihu.apps.googleusercontent.com">
            <Provider store={store}>
                <BrowserRouter>
                    <RoutePage/>
                </BrowserRouter>
            </Provider>
        </GoogleOAuthProvider>
    );
}

export default App;
