import {useDispatch} from "react-redux";
import {setSuccessSubmitForm} from "../../reducers/request-document.reducer";
import React from "react";
import {googleLogout} from "@react-oauth/google";

export default function SuccessAlertComponent() {
    const dispatch = useDispatch()

    const logOut = () => {
        googleLogout();
        localStorage.clear()
        window.location.reload()
    };

    return (
        <div className={'container'}>
            <div className={'flex justify-end w-screen mt-5 pr-10'}>
                <button className={'border-2 px-5 py-1 rounded-md'} onClick={logOut}>Sign out</button>
            </div>
            <div className="mx-auto h-screen w-screen">
                <div className={'row justify-center items-end pb-5'}>
                    <img src={require('../../assets/images/logo-avo.png')} alt={'logo-avo-img'} width={80}
                         height={80}/>
                    <h1 className="text-2xl font-bold text-center">Corporate Document System</h1>
                </div>
                <div className="w-full max-w-md mx-auto bg-white p-10 rounded-md border-2 border-gray-200 text-center">
                    <h1 className="text-2xl font-bold mb-6">Pengajuan Berhasil</h1>
                    <div className="flex justify-center my-10">
                        <img bg={'white'} src={require('../../assets/images/success-icon.png')} width={96} height={96}
                             alt={'success-img'}/>
                    </div>
                    <div className="my-10">
                        Pengajuan dokumen anda berhasil dilakukan, silakan menunggu konfirmasi lebih lanjut melalui email
                        anda.
                    </div>
                    <button onClick={() => {
                        dispatch(setSuccessSubmitForm(false))
                    }}
                            className="w-2/4 bg-indigo-500 text-white text-sm font-bold py-2 px-4 rounded-md hover:bg-indigo-600 transition duration-300">
                        Ajukan Dokumen Lain
                    </button>
                </div>
            </div>
        </div>
    )
}
