import axios from "axios";

let instance = null;

function makeInstance() {
    if (instance) {
        return instance;
    }
    instance = axios.create({baseURL: process.env.REACT_APP_BASE_URL});

    return instance;
}

const axiosInstance = makeInstance();

const setAuthHeader = accessToken => {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

const resetAuthHeader = () => {
    delete axiosInstance.defaults.headers.common.Authorization;
};

export const getLocalAccessToken = (type) => {
    try {
        const token = localStorage.getItem('token')
        return token
    } catch (error) {
        console.log('failed get local token', error)
    }
    return null
}

export {setAuthHeader, resetAuthHeader};

export default axiosInstance
