import TableComponent from "../../commons/table-component";
import {useEffect, useState} from "react";
import {
    approveDocumentApi,
    exportDocumentRequestApi, getDepartmentListApi,
    getListRequestDocumentApi,
    rejectDocumentApi
} from "../../apis/request-form.api";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import ModalApproveComponent from "../../commons/modal-approve.component";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedRowKeys} from "../../reducers/request-document.reducer";
import ModalRejectComponent from "../../commons/modal-reject.component";
import SearchIcon from "../../assets/images/search.svg";
import FilterIcon from '../../assets/images/filter.svg'
import {setToolsTotalData} from "../../reducers/tools.reducer";
import ModalSortBy from "../../commons/modal-sortby.component";
import ModalFilterDepartment from "../../commons/modal-filter-department.component";

const TAB_LIST = [
    {
        label: 'Semua Request',
        key: 'all'
    },
    {
        label: 'Submitted',
        key: 'submitted'
    },
    {
        label: 'Approved',
        key: 'approved'
    },
    {
        label: 'Rejected',
        key: 'rejected'
    },
]

export default function RequestListComponent() {
    const columns = [
        {
            type: 'checkbox',
            classNameHeader: 'px-3',
        },
        {
            name: 'ID Doc',
            classNameHeader: 'pl-4',
            dataIndex: 'id',
            render: (id) => (
                <p className={"font-regular text-forged-steel pl-5"}>{id}</p>
            )
        },
        {
            name: 'Type Request',
            dataIndex: 'type',
            render: (type) => (
                <p className={"font-regular text-forged-steel"}>{
                    type === 'request_document' ? 'Request Document Perusahaan' : 'Request Watermak Document'
                }</p>
            )
        },
        {
            name: 'PIC',
            dataIndex: 'pic_email',
            render: (pic_email) => (
                <p className={"font-regular text-forged-steel"}>{pic_email}</p>
            )
        },
        {
            name: 'Tujuan Penggunaan',
            dataIndex: 'intended_use',
            render: (intended_use) => (
                <div className={'w-96'}>
                    <p className={"font-regular text-forged-steel"}>{intended_use}</p>
                </div>
            )
        },
        {
            name: 'Create at',
            dataIndex: 'created_at',
            render: (created_at) => (
                <p className={"font-regular text-forged-steel"}>{dayjs(created_at).format('DD-MM-YYYY')}</p>
            )
        },
        {
            name: 'Status',
            dataIndex: 'status',
            render: (status) => (
                <p className={"font-regular text-forged-steel"}>{status}</p>
            )
        },
        {
            name: 'Link Document',
            dataIndex: 'document_link',
            render: (document_link) => (
                <Link target='_blank' to={document_link}>
                    <p className={"font-regular text-forged-steel"}>View Document</p>
                </Link>
            )
        },
        {
            name: 'Action',
            render: (record) => (
                <Link to={`/request-document-detail/${record?.id}`}>
                    <p className={"font-regular text-forged-steel"}>Detail</p>
                </Link>
            )
        }
    ]
    const [dataSource, setDataSource] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const options = [
        {id: 'none', name: 'Update Status'},
        {id: 'approve', name: 'Approve'},
        {id: 'rejected', name: 'Reject'}]
    const [selectedStatus, setSelectedStatus] = useState()
    const [isShowApprove, setShowApprove] = useState(false)
    const {selectedRowKeys} = useSelector(state => state.requestDocumentReducer)
    const dispatch = useDispatch()
    const email = localStorage.getItem('email')
    const nip = localStorage.getItem('nip')
    const [isShowReject, setShowReject] = useState(false)
    const [note, setNote] = useState('')
    const [watermark, setWatermark] = useState(false)
    const [keyword, setKeyword] = useState('')
    const [selectedDepartment, setSelectedDepartment] = useState([])
    const [orderBy, setOrderBy] = useState('created_at-desc')
    const [isShowFilter, setShowFilter] = useState(false)
    const [isShowSort, setShowSort] = useState(false)
    const [isErrorReject, setErrorReject] = useState(false)
    const [activeTab, setActiveTab] = useState('all')
    const [departmentList, setDepartmentList] = useState([])

    const {currentPage} = useSelector(state => state.toolsReducer)

    useEffect(() => {
        getData()

        return () => {
            dispatch(setSelectedRowKeys([]))
        }
    }, [keyword, activeTab, currentPage])

    useEffect(() => {
        getDepartmentListApi()
            .then(res => {
                setDepartmentList(res?.data)
            })
            .catch(err => console.log("err :", err))
    }, [])

    const getData = () => {
        const body = {
            limit: 20,
            page: currentPage,
            order: orderBy,
            status: activeTab === 'all' ? '' : activeTab,
            search: keyword,
            departments: selectedDepartment
        }
        setIsLoading(true)
        getListRequestDocumentApi(body)
            .then(result => {
                setDataSource(result?.data?.rows)
                dispatch(setToolsTotalData(result?.data?.total_result))
                setIsLoading(false)
            })
            .catch(err => {
                console.log("err get list request document :", err)
                setIsLoading(false)
            })
    }

    const onApplyFilter = () => {
        setShowFilter(false)
        getData()
    }

    const onApplyHandler = () => {
        if (selectedStatus === 'approve') {
            setShowApprove(true)
        }

        if (selectedStatus === 'rejected') {
            setShowReject(true)
        }
    }

    const onSelectHandler = (e) => setSelectedStatus(e)

    const onApproveHandler = () => {
        const body = {ids: selectedRowKeys, watermark, email, nip}
        approveDocumentApi(body)
            .then(result => {
                window.location.reload()
            })
            .catch(err => {
                setShowApprove(false)
                alert('failed to approve document')
            })
    }

    const onChangeSelectedRowKeys = (array) => dispatch(setSelectedRowKeys(array))

    const onChangeHandler = (e, slug) => {
        if (slug === 'note') {
            setNote(e)
        } else {
            setWatermark(!watermark)
        }
    }

    const onRejectHandler = () => {
        if (!!note) {
            const body = {ids: selectedRowKeys, reason: note, email, nip}
            rejectDocumentApi(body)
                .then(result => {
                    window.location.reload()
                    setErrorReject(false)
                })
                .catch(err => {
                    setShowReject(false)
                    setErrorReject(false)
                    alert('failed to reject document !!')
                })
        }else {
            setErrorReject(true)
        }

    }

    const onExportHandler = () => {
        exportDocumentRequestApi({search: keyword, ids: selectedRowKeys, order: '', status: '', department: ''})
            .then(res => {
                dispatch(setSelectedRowKeys([]))
                const link = document.createElement('a')
                link.href = res?.data?.download_url
                link.setAttribute('download', `request-document-${Date.now()}.xlsx`)
                document.body.appendChild(link)
                link.click()
            })
            .catch(err => alert('error export document !!'))
    }

    const onKeyupHandler = (event) => {
        if (event.key === 'Enter' || event.target.value.length > 3) {
            setKeyword(event.target.value)
        }
    }

    const onFilterHandler = () => setShowFilter(true)

    const onSortHandler = () => setShowSort(true)

    const checkedHandler = (event) => {
        let findIndex = selectedDepartment.findIndex(s => s === event.target.name)
        let newData = []

        if (findIndex > -1) {
            let filteredData = selectedDepartment.filter(s => s !== event.target.name)
            newData = filteredData
        } else {
            newData = [...selectedDepartment, event.target.name]
        }
        setSelectedDepartment(newData)
    }

    const onChangeTab = (key) => setActiveTab(key)

    const onChangeOrder = (value) => setOrderBy(value)

    const onCancelFilterHandler = () => {
        setShowFilter(false)
        setSelectedDepartment([])
    }

    return (
        <>
            <div className={"col space-y-4 mx-10"}>
                <div className={"row text-forged-steel font-semibold"}>
                    {
                        TAB_LIST.map((item, index) => (
                            <div key={index} onClick={() => onChangeTab(item.key)}
                                 className={`row justify-center py-3 w-28 ${activeTab === item.key && 'border-b-2 border-monstera -mb-px bg-white'} cursor-pointer`}>
                                <p className={`${activeTab === item.key && 'font-Montserrat-Bold text-monstera'}`}>{item.label}</p>
                            </div>
                        ))
                    }
                </div>
                <div className={'row justify-between'}>
                    <div className={'row justify-between gap-4'}>
                        <div
                            className={`row items-center px-2 rounded-lg h-9 w-56 border border-desolace-dew bg-ceramic`}>
                            <img className={"h-5"} src={SearchIcon} alt={'search'}/>
                            <input
                                placeholder={'cari nama dokumen...'}
                                className={"bg-ceramic font-normal border-0 outline-0 px-3 w-full placeholder:italic"}
                                onKeyUp={onKeyupHandler}
                            />
                        </div>
                        <button onClick={onFilterHandler}
                            className={'row items-center gap-1 border p-2 rounded-lg border border-desolace-dew bg-ceramic font-bold'}>
                            <img className={"h-5"} src={FilterIcon} alt="filter"/>
                            <p>Filter</p>
                            <input type="checkbox" readOnly checked={selectedDepartment?.length > 0 ? true : false}/>
                        </button>
                        <button onClick={onSortHandler}
                                className={'border p-2 rounded-lg border border-desolace-dew bg-ceramic font-bold'}>
                            Sort By
                        </button>
                        <button className={'border p-2 rounded-lg bg-blue-800 font-bold text-white'}
                                onClick={onExportHandler}>
                            Export
                        </button>
                    </div>
                    <div className={'row gap-4'}>
                        {
                           (activeTab === 'all' || activeTab === 'submitted') &&
                                <>
                                    <select
                                        onChange={(e) => onSelectHandler(e.target.value)}
                                        className="w-full p-2 border border-desolace-dew bg-ceramic rounded-md focus:outline-none focus:border-indigo-500">
                                        {
                                            options.map(option => <option key={option?.id}
                                                                          value={option?.id}>{option?.name}</option>)
                                        }
                                    </select>
                                    <button className={'border px-5 py-1 rounded-lg bg-green-800 text-white font-bold'}
                                            onClick={onApplyHandler}>Apply
                                    </button>
                                </>
                        }
                    </div>
                </div>
                <TableComponent
                    columns={columns}
                    selectedRowKeys={selectedRowKeys}
                    onChangeSelectedRowKeys={onChangeSelectedRowKeys}
                    headerClassName={"bg-windmill-wings font-semibold text-left text-monstera h-8"}
                    dataSource={dataSource}
                    pagination={true}
                    loading={isLoading}
                    hoverItem={true}
                />
            </div>
            {isShowApprove && <ModalApproveComponent isShow={isShowApprove}
                                                     onApprove={onApproveHandler}
                                                     closeModalHandler={() => setShowApprove(false)}
                                                     onChangeHandler={onChangeHandler}/>}
            {isShowReject &&
            <ModalRejectComponent isShow={isShowReject}
                                  onReject={onRejectHandler}
                                  onChangeHandler={onChangeHandler}
                                  isErrorReject={isErrorReject}
                                  closeModalHandler={() => setShowReject(false)}/>}
            {
                isShowFilter &&
                <ModalFilterDepartment isVisible={isShowFilter} selectedDepartment={selectedDepartment}
                                       departmentList={departmentList} checkedHandler={checkedHandler}
                                       onCancelHandler={onCancelFilterHandler} onApplyFilter={onApplyFilter}
                                       onCloseHandler={() => setShowFilter(false)}
                />
            }
            {
                isShowSort &&
                <ModalSortBy isVisible={isShowSort} selectedItem={orderBy}
                             onApplyHandler={() => {
                                 setShowSort(false)
                                 getData()
                             }} onChangeOrder={onChangeOrder} onCloseHandler={() => setShowSort(false)}/>
            }
        </>
    )
}
