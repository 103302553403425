import React, {useEffect, useRef, useState} from "react";
import XCircleIcon from "../assets/images/x.svg"

const MultipleSelectListComponent = (props) => {
    const {item, onChangeMultipleSelect, defaultValue} = props
    const searchData = useRef()
    const [showListOption, setShowListOption] = useState(false)
    const [listOption, setListOption] = useState([])
    const [listSelected, setListSelected] = useState(defaultValue ?? [])

    useEffect(() => {
        setListOption(item?.options)
    }, [item?.options])

    const searchListOption = (event) => {
        const keyword = event.target.value

        if (keyword.length === 0) {
            setShowListOption(false)
        } else {
            const filterListOption = item?.options.filter(list => {
                if (!!list.name && list.name.toLowerCase().includes(keyword.toLowerCase())) {
                    return true
                }
            })
            setListOption(filterListOption)
            setShowListOption(true)
        }
    }

    const selectOption = (data) => {
        const addDataSelected = [...listSelected, ...data]
        setListSelected(addDataSelected)
        onChangeMultipleSelect(item.key, addDataSelected)
        setShowListOption(false)
        searchData.current.value = ''
    }

    const deleteItem = (id) => {
        const deleteDataSelected = listSelected.filter(list => list.id !== id)
        setListSelected(deleteDataSelected)
        onChangeMultipleSelect(item, deleteDataSelected)
        onChangeMultipleSelect(item.key, deleteDataSelected)
    }

    return (
        <div className={"col font-regular text-forged-steel relative"}>
            <div
                className={`row items-center px-2 h-9 border border-placebo ${listSelected.length > 0 ? 'rounded-t-lg' : 'rounded-lg'}`}>
                <input type={"text"} name={item.key} id={item.key}
                       placeholder={!!item?.placeholder ? item?.placeholder : 'cari...'}
                       className={"border-0 outline-0 px-3 placeholder:italic w-full"}
                       onClick={() => setShowListOption(true)}
                       onKeyUp={searchListOption}
                       ref={searchData}/>
            </div>
            <div className={listSelected.length > 0 ? 'block' : 'hidden'}>
                <div
                    className={"row flex-wrap p-5 bg-white border-x border-b rounded-b-lg border-placebo max-h-64 overflow-scroll"}>
                    {listSelected.map((list, index) => (
                        <div key={index}
                             className={"row items-center space-x-4 mr-4 bg-doctor rounded-lg border border-liberated-lime mb-2"}>
                            <img src={XCircleIcon} alt="deleteList" className={"h-5 cursor-pointer order-last px-4"}
                                 onClick={() => deleteItem(list.id)}/>
                            <p className={"w-fit"}>{list.name}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div
                className={`${showListOption ? 'block' : 'hidden'} 
                col absolute w-11/12 top-8 bg-white shadow rounded-lg max-h-52 overflow-scroll z-10`}>
                {listOption.length > 0 ? listOption.map((list, index) => (
                    <div key={index}
                         className={"row hover:bg-apple-flower hover:text-monstera items-center p-3 space-x-4 cursor-pointer"}
                         onClick={() => selectOption([list])}>
                        <p>{list.name}</p>
                    </div>
                )) : <p className={"p-3"}>Not Found</p>}
            </div>
        </div>
    )
}

export default MultipleSelectListComponent
