import {useLocation} from "react-router-dom";

export const Breadcrumb = ({data}) => {
    const location = useLocation();
    const pathRaw = location.pathname.split('/')

    return (
        <div className={"row items-center text-monstera space-x-3"}>
            <p className={"font-Montserrat-Bold text-xl"}>{data[pathRaw[1]]}</p>
        </div>
    )
}
