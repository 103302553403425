import RequestFormComponent from "./request-form.component";
import SuccessAlertComponent from "./success-alert.component";
import {useSelector} from "react-redux";

export default function RequestDocumentPage() {
    const {requestDocumentReducer} = useSelector(state => state)
    const {isShowSuccessComponent} = requestDocumentReducer

    return isShowSuccessComponent ? <SuccessAlertComponent/> : <RequestFormComponent/>
}
