import Layout from "../layout";
import RequestDetailInfoComponent from "./request-detail-info.component";
import RequestDetailHistoryComponent from "./request-detail-history.component";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {approveDocumentApi, getDetailRequestDocumentApi, rejectDocumentApi} from "../../apis/request-form.api";
import ModalRejectComponent from "../../commons/modal-reject.component";
import ModalApproveComponent from "../../commons/modal-approve.component";

export default function RequestDocumentDetail() {
    const {id} = useParams()
    const [requestDetail, setRequestDetail] = useState()
    const [selectedStatus, setSelectedStatus] = useState()
    const [isShowReject, setShowReject] = useState(false)
    const [isShowApprove, setShowApprove] = useState(false)
    const [note, setNote] = useState('')
    const [watermark, setWatermark] = useState(false)
    const email = localStorage.getItem('email')
    const nip = localStorage.getItem('nip')

    useEffect(() => {
        if (!!id) {
            let body = {id: id}
            getDetailRequestDocumentApi(body)
                .then(result => {
                    setRequestDetail(result?.data)
                })
                .catch(err => {
                    console.log("err get detail request :", err)
                })
        }
    }, [])

    const onSelectHandler = (value) => {
        setSelectedStatus(value)
    }

    const onApplyHandler = () => {
        if (selectedStatus === 'approve') {
            setShowApprove(true)
        }

        if (selectedStatus === 'rejected') {
            setShowReject(true)
        }
    }

    const onChangeHandler = (e, slug) => {
        if (slug === 'note') {
            setNote(e)
        } else {
            setWatermark(!watermark)
        }
    }

    const onRejectHandler = () => {
        if (!!note) {
            const body = {ids: [requestDetail?.id], reason: note, email, nip}
            rejectDocumentApi(body)
                .then(result => {
                    window.location.reload()
                })
                .catch(err => {
                    setShowReject(false)
                    alert('failed to process document')
                })
        }

    }

    const onApproveHandler = () => {
        const body = {ids: [requestDetail?.id], watermark, email, nip}
        approveDocumentApi(body)
            .then(result => {
                window.location.reload()
            })
            .catch(err => {
                setShowApprove(false)
                alert('failed to process document')
            })
    }

    return (
        <>
            <Layout>
                <div className={"row px-10 space-x-5 my-5"}>
                    {
                        requestDetail?.id &&
                        <>
                            <RequestDetailInfoComponent requestDetail={requestDetail}/>
                            <RequestDetailHistoryComponent requestDetail={requestDetail} onSelect={onSelectHandler}
                                                           onApply={onApplyHandler}/>
                        </>
                    }
                </div>
            </Layout>
            {isShowApprove && <ModalApproveComponent isShow={isShowApprove} onApprove={onApproveHandler}
                                                     onChangeHandler={onChangeHandler}
                                                     closeModalHandler={() => setShowApprove(false)}/>}
            {isShowReject &&
            <ModalRejectComponent isShow={isShowReject} onReject={onRejectHandler} onChangeHandler={onChangeHandler}
                                  closeModalHandler={() => setShowReject(false)}/>}
        </>
    )
}
