import RequestListComponent from "./request-list.component";
import Layout from "../layout";

export default function RequestList() {
    return (
        <Layout>
            <RequestListComponent/>
        </Layout>
    )
}
