import {useState} from "react";
import {googleLogout} from "@react-oauth/google";
import {Breadcrumb} from "./breadcrumb";

const BREADCRUMBS = {
    'request-document-detail': 'Request Document Detail',
    'request-document-list': 'Request Document List',
    'legal-document': 'Legal Document',
}

export const Header = () => {
    const [isDropdown, setIsDropdown] = useState(false)
    const role = localStorage.getItem('user_role')
    const email = localStorage.getItem('email')

    const showDropdownHandler = () => {
        setIsDropdown(!isDropdown)
    }

    const logoutHandler = () => {
        googleLogout();
        localStorage.clear()
        window.location.reload()
    }

    return (
        <div className={"row items-center justify-between px-10 py-4"}>
            <Breadcrumb data={BREADCRUMBS}/>
            <div className={"h-14  space-x-10 rounded px-2 relative"}>
                <div className={"border px-4 py-2 row items-center space-x-3 cursor-pointer rounded-lg"}
                     onClick={showDropdownHandler}>
                    <div>
                        <p className={"font-semibold"}>{email}</p>
                        <p className={'capitalize'}>{role}</p>
                    </div>
                </div>
                <div
                    className={`${isDropdown ? 'block' : 'hidden'} absolute right-0 top-14 bg-doctor w-full h-fit z-20`}>
                    <div className={"px-3 py-1 rounded-lg cursor-pointer hover:bg-monstera hover:text-white border-2"}
                         onClick={logoutHandler}>
                        <p>Logout</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
