import CloseIcon from "../assets/images/close-icon.svg";
import Modal from "./modal.component";

export default function ModalSortBy(props) {
    const orderByList = [{id: 'created_at-desc', name: 'Tanggal terbaru'}, {
        id: 'created_at-asc',
        name: 'Tanggal terlama'
    }]

    const {
        isVisible,
        selectedItem,
        onApplyHandler,
        onChangeOrder,
        onCloseHandler
    } = props

    return (
        <Modal
            visibility={isVisible}
            content={
                <div className={"relative"}>
                    <div className={"col justify-center w-96 h-auto p-5 bg-white rounded-xl"}>
                        <p className={"text-monstera text-lg text-black"}>Sort By</p>
                        <div className={"col space-y-4 max-h-56 my-4 overflow-scroll pr-10"}>
                            {orderByList?.map((order, index) => (
                                <div key={index} className={"row space-x-5 pl-8 w-full"}>
                                    <input type="checkbox"
                                           value={selectedItem}
                                           className={`accent-monstera`}
                                           name={order?.id}
                                           onChange={(e) => onChangeOrder(e.target.name)}
                                           checked={order?.id === selectedItem}
                                    />
                                    <label
                                        className={"row space-x-1 justify-center text-forged-steel font-regular"}>
                                        {order?.name}
                                    </label>
                                </div>
                            ))}
                        </div>
                        <div className={'row justify-center'}>
                            <button className={'border px-5 py-1 rounded-lg text-white bg-indigo-500 text-sm'}
                                    onClick={onApplyHandler}>Apply</button>
                        </div>
                        <img src={CloseIcon} alt="closeButton"
                             className={`w-5 absolute right-4 top-2 cursor-pointer`}
                             onClick={onCloseHandler}/>
                    </div>
                </div>
            }
        />
    )
}
