import {useGoogleLogin} from '@react-oauth/google';
import {useEffect, useState} from "react";
import axios from "axios";
import {validateUserApi} from "../../apis/request-form.api";

export default function Login() {
    const [user, setUser] = useState([])

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(() => {
        if (!!user?.access_token) {
            axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                headers: {Authorization: `Bearer ${user.access_token}`, Accept: 'application/json'}
            }).then((res) => {
                getUserDetail(res?.data)
            }).catch((err) => {
                console.log(err)
            });
        }
    }, [user]);

    const getUserDetail = (data) => {
        let body = {email: data?.email}
        validateUserApi(body) //validate user email
            .then(result => {
                localStorage.setItem('user_role', result.data.role)
                localStorage.setItem('email', result.data.email)
                localStorage.setItem('nip', result.data.nip)
                window.location.reload()
            })
            .catch(err => {
                alert('Invalid User Email !!')
            })
    }

    return (
        <div className="container mx-auto py-10 h-screen">
            <h1 className="text-2xl font-bold mb-10 text-center">Corporate Document System</h1>
            <div className="w-full max-w-md mx-auto bg-white p-10 rounded-md border-2 border-gray-200 text-center">
                <h1 className="text-2xl font-bold mb-6">Login</h1>
                <div className="my-10">
                    Silakan login dengan akun email anda untuk mengakses form request dokumen
                </div>
                <button className={'border-2 px-5 py-1 rounded-md'} onClick={() => login()}>Sign in with
                    Google</button>
            </div>
        </div>
    )
}
