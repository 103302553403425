export default function RequestDetailInfoComponent({requestDetail}) {
    let fileNames = requestDetail?.files?.length && requestDetail?.files.map(file => file?.filename)

    return (
        <div className={'col gap-4 w-4/5 p-5 rounded-lg border-2 border-desolace-dew'}>
            <div className={"row justify-between"}>
                <div>
                    <p className={'capitalize text-base font-bold'}>{requestDetail?.pic_name}</p>
                    <p>{requestDetail?.pic_department}</p>
                </div>
                <p className={'font-bold'}>{requestDetail?.created_at_formatted}</p>
            </div>
            <div className={"row gap-2"}>
                <p className={'font-bold w-32'}>Type request</p>
                <p className={'font-bold'}>: {requestDetail?.type === 'request_document' ? 'Request Document Perusahaan' : 'Request Watermark Document'}</p>
            </div>
            <div className={'row gap-2'}>
                <p className={'font-bold w-32'}>Tujuan Penggunaan</p>
                <p>: {requestDetail?.intended_use}</p>
            </div>
            <div className={"row gap-2"}>
                <p className={'font-bold w-32'}>Dibuat Oleh</p>
                <p>: {requestDetail?.made_by}</p>
            </div>
            <div className={"row gap-2"}>
                <p className={'font-bold w-32'}>Ditujukan Untuk</p>
                <p>: {requestDetail?.addressed_to}</p>
            </div>
            <div className={"row gap-2"}>
                <p className={'font-bold w-32'}>Nama File</p>
                {
                    !!fileNames?.length &&
                    <p>: {fileNames.toString()}</p>
                }
            </div>
            <div className={"col gap-2"}>
                <p className={'font-bold'}>File Download</p>
                {
                    !!requestDetail?.files?.length &&
                    requestDetail?.files?.map((file, index) => <a key={index} target={'_blank'}
                                                                  href={file?.fileurl}>{file?.fileurl}</a>)
                }
            </div>
        </div>
    )
}
