export default function RequestDetailHistoryComponent({requestDetail, onSelect, onApply}) {
    const options = [
        {id: 'none', name: 'Update Status'},
        {id: 'approve', name: 'Approve'},
        {id: 'rejected', name: 'Reject'}
    ]

    return (
        <div className={"w-1/5 col gap-2"}>
            <div className={'p-4 col gap-4 border-2 border-desolace-dew bg-ceramic rounded-lg'}>
                <div className={'row items-center gap-2'}>
                    <p className={'font-bold'}>Status Request :</p>
                    <p className={`${requestDetail?.status === 'rejected' ? 'text-red-400' : 'text-orange-500'} font-bold capitalize text-md`}>{requestDetail?.status}</p>
                </div>
                {
                    requestDetail?.status === 'submitted' &&
                    <div className={'row gap-2'}>
                        <select
                            onChange={(e) => onSelect(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500">
                            {
                                options.map(option => <option key={option?.id}
                                                              value={option?.id}>{option?.name}</option>)
                            }
                        </select>
                        <button className={'border px-3 py-1 rounded bg-green-800 text-white'} onClick={onApply}>Apply
                        </button>
                    </div>
                }
            </div>
            <div className={'p-4 border-2 border-desolace-dew bg-ceramic rounded-lg'}>
                <div className={'pb-2'}>
                    <p className={'font-bold'}>History Request :</p>
                </div>
                {requestDetail?.logs.map((log, index) => (
                    <div key={index} className={"row items-start space-x-5"}>
                        <div className={"col items-center"}>
                            <div className={`border-2 p-1 rounded-full 
                            ${index === 0 ? 'border-monstera bg-white' : 'border-doctor bg-doctor'}`}>
                                <div className={`p-[6px] rounded-full 
                                ${index === 0 ? 'bg-monstera' : 'bg-casa-verde'}`}></div>
                            </div>
                            {requestDetail?.logs.length - 1 !== index && (
                                <div className={`w-[2px] h-14 
                                ${index === 0 ? 'bg-monstera' : 'bg-casa-verde'}`}></div>
                            )}
                        </div>
                        <div className={"col text-eerie-black"}>
                            <p className={"max-h-12 overflow-scroll font-bold"}>{log?.description}</p>
                            <p>Modified by :</p>
                            <p>{log?.email}</p>
                            <p>{log?.created_at_formatted}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className={'p-4 border-2 border-desolace-dew bg-ceramic rounded-lg'}>
                <p className={'font-bold'}>Notes Admin</p>
                <p className={'py-2'}>{requestDetail?.rejected_reason}</p>
            </div>
        </div>
    )
}
