import {Sidebar} from "../../commons/sidebar";
import {Header} from "../../commons/header";

export default function Layout({children}) {
    const role = localStorage.getItem('user_role')

    return (
        <div>
            {
                role === 'admin' ?
                    <div className={"row text-xs"}>
                        <Sidebar/>
                        <div className={"w-full"}>
                            <Header/>
                            {children}
                        </div>
                    </div>
                    :
                    <>
                        {children}
                    </>
            }
        </div>
    )
}
