import {createSlice} from '@reduxjs/toolkit'

export const toolsSlice = createSlice({
    name: 'tools',
    initialState: {
        currentPage: 1,
        pageSize: 20,
        totalData: 0,
    },
    reducers: {
        setToolsCurrentPage: (state, action) => {
            state.currentPage = action.payload
        },
        setToolsPageSize: (state, action) => {
            state.pageSize = action.payload
        },
        setToolsTotalData: (state, action) => {
            state.totalData = action.payload
        },
        setToolsInitialState: (state) => {
            state.currentPage = 1
            state.pageSize = 20
        }
    },
})

export const {
    setToolsCurrentPage,
    setToolsPageSize,
    setToolsTotalData,
    setToolsInitialState
} = toolsSlice.actions
const toolsReducer = toolsSlice.reducer

export default toolsReducer
