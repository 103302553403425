import {useNavigate} from "react-router-dom";
import RequestDocumentPage from "../request-form";
import {setSuccessSubmitForm} from "../../reducers/request-document.reducer";
import {useDispatch} from "react-redux";

export default function LandingPage() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const role = localStorage.getItem('user_role')

    const onClickHandler = (slug) => {
        if (slug === 'admin') {
            navigate('/request-document-list')
        } else {
            dispatch(setSuccessSubmitForm(false))
            navigate('/request-document')
        }
    }

    return (
        role === 'user'
            ?
            <RequestDocumentPage/>
            :
            <div className="container mx-auto py-10 h-screen">
                <h1 className="text-2xl font-bold mb-10 text-center">Corporate Document System</h1>
                <div className="w-full max-w-md mx-auto bg-white p-10 rounded-md border-2 border-gray-200 text-center">
                    <h1 className="text-2xl font-bold mb-6">Login</h1>
                    <button className={'border-2 px-5 py-1 my-5 rounded-md w-56 font-bold'}
                            onClick={() => onClickHandler('admin')}>Login Sebagai Admin
                    </button>
                    <button className={'border-2 px-5 py-1 rounded-md w-56 font-bold'}
                            onClick={() => onClickHandler('user')}>Login Sebagai User
                    </button>
                </div>
            </div>
    )
}
