import React from "react";
import {Link, useLocation} from "react-router-dom";

export const Sidebar = () => {
    const location = useLocation();
    const pathRaw = location.pathname.split('/')

    const SIDEBAR = [
        {
            label: 'List Request Doc',
            path: 'request-document-list',
        },
        {
            label: 'Legal Document',
            path: 'legal-document',
        },
        {
            label: 'Request Document',
            path: 'request-document',
        }
    ]

    return (
        <div className={"relative w-64 bg-windmill-wings h-screen col items-center bg-gray-200"}>
            <div className={'py-5 row items-center justify-between px-2'}>
                <img src={require('../assets/images/logo-avo.png')} alt={'logo-avo-img'} width={80} height={80}/>
                <p className={'font-bold'}>Corporate Document System Admin</p>
            </div>
            <div className={"col px-4 w-full z-10 mt-8"}>
                {
                    SIDEBAR.map((item, index) => (
                        <Link key={index}
                              to={`/${item?.path}`}
                              className={`${pathRaw[1] === item.path ? 'bg-whisper-green rounded-lg' : 'bg-transparent'}`}>
                            <div
                                className={`row items-center font-semibold w-full h-11 px-4 space-x-4 cursor-pointer ${pathRaw[1] === item.path ? 'bg-monstera rounded-lg shadow-md text-white' : 'text-monstera'}`}>
                                {item.label}
                            </div>
                        </Link>
                    ))
                }
            </div>
        </div>
    )
}
