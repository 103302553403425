import React, {useEffect, useState} from "react";
import {
    createDocumentReviewApi,
    getCompanies,
    getLegalDocumentsApi,
    getUserDetail,
    uploadFile
} from "../../apis/request-form.api";
import {useDispatch} from "react-redux";
import {setSuccessSubmitForm} from "../../reducers/request-document.reducer";
import {googleLogout} from "@react-oauth/google";
import Modal from "../../commons/modal.component";
import MultipleSelectListComponent from "../../commons/multiple-select-list.component";

const formRequestReviewDocument = [
    {
        key: 'pic_name',
        label: 'Nama PIC',
        placeholder: 'Masukkan nama PIC',
        type: 'text',
    },
    {
        key: 'pic_department',
        label: 'Department',
        placeholder: 'Masukkan nama department',
        type: 'text',
    },
    {
        key: 'filename',
        label: 'Nama File',
        placeholder: 'Inputkan nama file',
        type: 'text',
    },
    {
        key: 'file',
        label: 'Upload File',
        placeholder: 'Upload file',
        type: 'file',
        required: true
    },
    {
        key: 'made_by',
        label: 'Dibuat Oleh',
        placeholder: 'Pilih nama department',
        type: 'option',
        options: [],
    },
    {
        key: 'addressed_to',
        label: 'Ditujukan Untuk',
        placeholder: 'Inputkan Badan Hukum / Badan Usaha / Perseorangan',
        type: 'text'
    },
    {
        key: 'intended_use',
        label: 'Tujuan Penggunaan Dokumen',
        placeholder: 'Tuliskan tujuan penggunaan dokumen',
        type: 'textarea',
    },
]

const formRequestDocument = [
    {
        key: 'pic_name',
        label: 'Nama PIC',
        placeholder: 'Masukkan nama PIC',
        type: 'text',
    },
    {
        key: 'pic_department',
        label: 'Department',
        placeholder: 'Masukkan nama department',
        type: 'text',
    },
    {
        key: 'file_type',
        label: 'Jenis Request File',
        placeholder: 'Masukkan jenis request file',
        type: 'multiple-option',
        options: []
    },
    {
        key: 'made_by',
        label: 'Dibuat Oleh',
        placeholder: 'Pilih nama department',
        type: 'option',
        options: [],
    },
    {
        key: 'addressed_to',
        label: 'Ditujukan Untuk',
        placeholder: 'Inputkan Badan Hukum / Badan Usaha / Perseorangan',
        type: 'text'
    },
    {
        key: 'intended_use',
        label: 'Tujuan Penggunaan Dokumen',
        placeholder: 'Tuliskan tujuan penggunaan dokumen',
        type: 'textarea',
    },
]

export default function RequestFormComponent() {
    const [file, setFile] = useState()
    const formType = [{id: 'request_document', name: 'Request Document Perusahaan'},
        {id: 'request_review_document', name: 'Request Watermark Document'}]
    const [selectedType, setSelectedType] = useState('request_document')
    const [companies, setCompanies] = useState([])
    const [legalDocuments, setLegalDocument] = useState([])
    const dispatch = useDispatch()
    const [itemDetail, setItemDetail] = useState({
        type: selectedType,
        pic_email: '',
        pic_nip: '',
        pic_name: '',
        pic_department: '',
        filename: '',
        made_by: null,
        addressed_to: '',
        intended_use: '',
        file: '',
        file_type: [],
        legal_document_ids: ''
    })
    const [isShow, setShow] = useState(false)
    const [isValid, setValid] = useState(true)
    const [isDisabled, setDisabled] = useState(false)

    useEffect(() => {
        getUserDetailData()
        getCompaniesData()
        getLegalDocumentData()
    }, [])

    const getFormItem = () => {
        if (selectedType === 'request_review_document') {
            let copyFormItems = [...formRequestReviewDocument]
            copyFormItems.forEach(i => i.key === 'made_by' ? i.options = companies : null)
            return copyFormItems
        } else {
            let copyFormItems = [...formRequestDocument]
            copyFormItems.forEach(i => i.key === 'file_type' ? i.options = legalDocuments : null)
            copyFormItems.forEach(i => i.key === 'made_by' ? i.options = companies : null)
            return copyFormItems
        }
    }

    const getCompaniesData = () => {
        getCompanies()
            .then(result => {
                setCompanies(result?.data)
            })
            .catch(err => {
                console.log("err get list companies :", err)
            })
    }

    const getLegalDocumentData = () => {
        const body = {limit: 200, page: 1, search: ""}
        getLegalDocumentsApi(body)
            .then(result => {
                let items = []
                result?.data?.rows.map(data => items.push({id: data?.id, name: data?.filename}))
                setLegalDocument(items)
            })
            .catch(err => {
                console.log("err :", err)
            })
    }

    const getUserDetailData = () => {
        const email = localStorage.getItem('email')
        let body = {email}
        getUserDetail(body)
            .then(result => {
                let {data} = result
                let newItem = {
                    ...itemDetail,
                    pic_email: data?.email,
                    pic_nip: data?.nip,
                    pic_name: data?.name,
                    pic_department: data?.department,
                }
                setItemDetail(newItem)
            })
            .catch(err => {
                console.log("err get list companies :", err)
            })
    }

    const onSubmit = e => {
        e.preventDefault()
        if (selectedType === 'request_document' && itemDetail?.file_type?.length < 0) {
            setValid(false)
        }
        if (selectedType === 'request_review_document' && !itemDetail?.made_by) {
            setValid(false)
        } else {
            setValid(true)
            setShow(true)
        }
    };

    const onProceedForm = () => {
        if (selectedType === 'request_document') {
            let legal_document_ids = itemDetail?.file_type.map(file => file?.id)
            let requestDetail = {
                type: selectedType,
                pic_email: itemDetail?.pic_email,
                pic_nip: itemDetail?.pic_nip,
                pic_name: itemDetail?.pic_name,
                pic_department: itemDetail?.pic_department,
                intended_use: itemDetail?.intended_use,
                legal_document_ids: legal_document_ids,
                made_by: Number(itemDetail?.made_by),
                addressed_to: itemDetail?.addressed_to,
            }
            doCreateDocumentReview(requestDetail)
        } else if (selectedType === 'request_review_document' && !!file?.length) {
            const form = new FormData();
            form.append('file', file[0]);
            uploadFile(form)
                .then(result => {
                    let fileurl = result?.data?.url
                    let requestDetailData = {
                        type: selectedType,
                        pic_email: itemDetail?.pic_email,
                        pic_nip: itemDetail?.pic_nip,
                        pic_name: itemDetail?.pic_name,
                        pic_department: itemDetail?.pic_department,
                        filename: itemDetail?.filename,
                        made_by: Number(itemDetail?.made_by),
                        addressed_to: itemDetail?.addressed_to,
                        intended_use: itemDetail?.intended_use,
                        fileurl,
                    }
                    doCreateDocumentReview(requestDetailData)
                })
                .catch(err => console.log("err :", err))
        } else {
            alert('data belum lengkap !')
        }
    }

    const doCreateDocumentReview = (requestDetailData) => {
        setDisabled(true)
        createDocumentReviewApi(requestDetailData)
            .then(result => {
                setShow(false)
                setDisabled(false)
                dispatch(setSuccessSubmitForm(true))
            })
            .catch(err => {
                setDisabled(false)
                console.log("err create document review:", err)
            })
    }

    const onChangeHandler = (e, key) => {
        let newData = {...itemDetail}
        switch (key) {
            case 'type':
                return setSelectedType(e.target.value)
            case 'file':
                setFile(e.target.files)
                newData[key] = e.target.value
                return setItemDetail(newData)
            default:
                newData[key] = e.target.value
                return setItemDetail(newData)
        }
    }

    const onChangeMultipleSelectHandler = (key, items) => {
        let newData = {...itemDetail}
        newData[key] = items
        return setItemDetail(newData)
    }

    const getInputComponent = (item) => {
        switch (item?.type) {
            case 'multiple-option':
                return <MultipleSelectListComponent item={item} onChangeMultipleSelect={onChangeMultipleSelectHandler}/>
            case 'option':
                return (
                    <select
                        onChange={(e) => onChangeHandler(e, item.key)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500">
                        <option className={'text-sm'}>-- pilih nama perusahaan --</option>
                        {
                            item?.options.map(option => <option key={option?.id}
                                                                value={option?.id}>{option?.name}</option>)
                        }
                    </select>
                )
            case 'textarea':
                return <textarea
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                    type={item?.type} name="name" placeholder={item?.placeholder}
                    onChange={(e) => onChangeHandler(e, item.key)}
                    value={itemDetail[item?.key]}
                    rows={5}
                />
            default:
                return <input
                    required
                    onChange={(e) => onChangeHandler(e, item.key)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                    type={item?.type} name="name" placeholder={item?.placeholder}
                    value={itemDetail[item?.key]}
                    accept={item?.type === 'file' ? '.pdf' : '*'}
                    disabled={item.key === 'pic_name' || item.key === 'pic_department' ? true : false}
                />
        }
    }

    const logOut = () => {
        googleLogout();
        localStorage.clear()
        window.location.reload()
    };

    const getErrorMsg = (item) => {
        switch (true) {
            case selectedType === 'request_document' && item?.key === 'file_type':
                return <p className={'text-red-500 italic text-xs mt-1'}>Jenis file wajib diisi</p>
            case selectedType === 'request_review_document' && item?.key === 'made_by':
                return <p className={'text-red-500 italic text-xs mt-1'}>nama perusahaan wajib diisi</p>
        }
    }

    const getButton = () => {
        switch (true){
            case selectedType === 'request_document' && (!itemDetail?.file_type?.length || !itemDetail?.made_by):
            case selectedType === 'request_review_document' && !itemDetail?.made_by:
                return(
                    <button
                        disabled={true}
                        className="w-full bg-gray-400 text-white text-sm font-bold py-2 px-4 rounded-md"
                        type="submit">Ajukan Permintaan
                    </button>
                )
            default:
                return (
                    <button
                        className="w-full bg-indigo-500 text-white text-sm font-bold py-2 px-4 rounded-md hover:bg-indigo-600 transition duration-300"
                        type="submit">Ajukan Permintaan
                    </button>
                )
        }
    }

    return (
        <>
            <div className="container">
                <div className={'flex justify-end w-screen mt-5 pr-10'}>
                    <button className={'border-2 px-5 py-1 rounded-md'} onClick={logOut}>Sign out</button>
                </div>
                <div className={'mx-auto h-screen w-screen'}>
                    <div className={'row justify-center items-end pb-5'}>
                        <img src={require('../../assets/images/logo-avo.png')} alt={'logo-avo-img'} width={80}
                             height={80}/>
                        <h1 className="text-2xl font-bold text-center">Corporate Document System</h1>
                    </div>
                    <form className="w-full max-w-lg mx-auto bg-white p-8 rounded-md border border-gray-300"
                          onSubmit={onSubmit}>
                        <h1 className="text-2xl font-bold mb-6 text-center">Form Request</h1>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Request Type*</label>
                            <select
                                onChange={(e) => onChangeHandler(e, 'type')}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500">
                                {
                                    formType.map(option => <option key={option?.id}
                                                                   onChange={(e) => onChangeHandler(e, 'type')}
                                                                   value={option?.id}>{option?.name}</option>)
                                }
                            </select>
                        </div>
                        {
                            getFormItem().map((item, index) => (
                                <div className="mb-4" key={index}>
                                    <label className="block text-gray-700 text-sm font-bold mb-2">{item?.label}*</label>
                                    {getInputComponent(item)}
                                    {
                                        item?.key === 'file' &&
                                        <p className={'text-red-500 text-xs mt-1'}>*format nama file :
                                            DD/mm/yyy-(Nama Dokumen)-PT AVO/LBI/BYVO-[Nama Partner/Mitra]</p>
                                    }
                                    {!isValid && getErrorMsg(item)}
                                </div>
                            ))
                        }
                        {getButton()}
                    </form>
                </div>
            </div>
            {
                !!isShow &&
                <Modal
                    visibility={isShow}
                    content={
                        <div className={"relative"}>
                            <div className={"col justify-center w-96 h-auto p-5 bg-white rounded-xl"}>
                                <p className={"text-monstera text-center font-bold text-base"}>Pengajuan Dokumen</p>
                                <p className={"text-monstera text-black text-base py-7"}>Apakah Anda yakin ingin
                                    mengajukan request dokumen ? </p>
                                <div className={'row justify-center gap-4'}>
                                    <button className={'border px-5 py-1 rounded-lg'}
                                            onClick={() => setShow(false)}>Batalkan
                                    </button>
                                    <button
                                        className={`border px-5 py-1 rounded-lg ${isDisabled ? 'bg-gray-300' : 'bg-indigo-500 text-white'}`}
                                        disabled={isDisabled}
                                        onClick={onProceedForm}>Ajukan
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
            }
        </>
    )
}
