export default function Modal(props){
    const {content, visibility, onClose} = props

    return (
        <div className={`${visibility ? 'block' : 'hidden'} fixed flex left-0 top-0 w-full h-screen justify-center items-center block z-20`}>
            <div onClick={onClose} className={"absolute bg-fortress-grey/[.65] inset-0"}/>
            {content}
        </div>
    )

}
