import {Route, Routes} from "react-router-dom";
import RequestDocumentPage from "../pages/request-form";
import Login from "../pages/login";
import RequestList from "../pages/request-list";
import PrivateRouter from "./private-router";
import LandingPage from "../pages/landing-page";
import RequestDocumentDetail from "../pages/request-detail";
import LegalDocument from "../pages/legal-document";

export default function RoutePage() {
    const role = localStorage.getItem('user_role')

    const ROUTE_LIST = [
        {
            path: '/request-document',
            component: <RequestDocumentPage/>
        },
        {
            path: '/home',
            component: <LandingPage/>
        },
        {
            path: '/request-document-list',
            component: <RequestList/>
        },
        {
            path: '/legal-document',
            component: <LegalDocument/>
        },
        {
            path: '/request-document-detail/:id',
            component: <RequestDocumentDetail/>
        }
    ]


    return (
        <Routes>
            <Route path="/" element={!!role ? <LandingPage/> : <Login/>}/>
            {
                ROUTE_LIST.map((item, index) => (
                    <Route key={index.toString()} path={item.path}
                           element={<PrivateRouter children={item.component}/>}/>
                ))
            }
        </Routes>
    )
}
