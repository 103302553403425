import CloseIcon from "../../assets/images/close-icon.svg";
import Modal from "../../commons/modal.component";

export default function CreateDocumentFormComponent({isShow, closeModalHandler, onSubmitHandler, onChangeHandler}) {
    return (
        <Modal
            visibility={isShow}
            onClose={closeModalHandler}
            content={
                <div className={"relative"}>
                    <div className={"col justify-center w-96 h-auto p-5 bg-white rounded-xl"}>
                        <p className={"text-monstera text-center font-bold text-lg"}>Create Legal Document</p>
                        <form onSubmit={(e) => onSubmitHandler(e)}>
                            <div className={'py-4'}>
                                <div className="mb-4">
                                    <label className="block text-gray-700 text-sm mb-2">Nama Dokumen*
                                        <input
                                            required
                                            onChange={(e) => onChangeHandler(e, 'fileName')}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                                            type={'text'} name="name" placeholder={'Masukkan nama dokumen'}
                                        />
                                    </label>
                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-700 text-sm mb-2">Upload File*
                                        <input
                                            required
                                            onChange={(e) => onChangeHandler(e, 'file')}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                                            type={'file'} name="file" placeholder={'Upload file'}
                                            accept={'.pdf'}
                                        />
                                    </label>
                                </div>
                            </div>
                            <button className={'border p-2 rounded-lg bg-blue-800 font-bold text-white w-full'}
                                    type="submit">
                                Create Document
                            </button>
                        </form>
                    </div>
                    <img src={CloseIcon} alt="closeButton"
                         className={`w-5 absolute right-4 top-2 cursor-pointer`}
                         onClick={closeModalHandler}/>
                </div>
            }
        />
    )
}
