import {createSlice} from '@reduxjs/toolkit'

export const requestDocumentSlice = createSlice({
    name: 'requestDocument',
    initialState: {
        isShowSuccessComponent: false,
        modalVisibility: false,
        selectedRowKeys: []
    },
    reducers: {
        setSuccessSubmitForm: (state, action) => {
            state.isShowSuccessComponent = action.payload
        },
        setModalVisibility: (state, action) => {
            state.modalVisibility = action.payload
        },
        setSelectedRowKeys: (state, action) => {
            state.selectedRowKeys = action.payload
        },
    },
})

export const {setModalVisibility, setSuccessSubmitForm, setSelectedRowKeys} = requestDocumentSlice.actions
const requestDocumentReducer = requestDocumentSlice.reducer

export default requestDocumentReducer
