import Modal from "./modal.component";
import CloseIcon from "../assets/images/close-icon.svg";

export default function ModalRejectComponent({isShow, onReject, onChangeHandler, closeModalHandler, isErrorReject = false}){
    return(
        <Modal
            visibility={isShow}
            content={
                <div className={"relative"}>
                    <div className={"col justify-center w-96 h-auto p-5 bg-white rounded-xl"}>
                        <p className={"text-monstera text-center font-bold text-lg text-black"}>Reject
                            Request</p>
                        <p className={"text-monstera text-black text-base py-5"}>Apa anda yakin melakukan
                            penolakan pada request dokumen ini ?</p>
                        <div className="col w-full gap-2 pb-8">
                            <label className="text-gray-700 text-sm font-bold">Catatan</label>
                            <textarea
                                required
                                onChange={(e) => onChangeHandler(e.target.value, 'note')}
                                className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                                name="name"
                            />
                            {
                                !!isErrorReject && <p className={'text-xs italic text-red-500'}>Catatan wajib diisi</p>
                            }
                        </div>
                        <div className={'row justify-center'}>
                            <button className={'border px-5 py-1 rounded-lg text-white bg-indigo-500 text-sm'}
                                    onClick={onReject}>Reject Request
                            </button>
                        </div>
                        <img src={CloseIcon} alt="closeButton"
                             className={`w-5 absolute right-4 top-2 cursor-pointer`}
                             onClick={closeModalHandler}/>

                    </div>
                </div>
            }
        />
    )
}
